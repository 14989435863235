<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol
                v-if="hasRoles([ROLE_TIM_IO])"
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.regional"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_TIM_IO])"
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.regional"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_TIM_IO])"
                :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:kabupaten="state.params.kabupaten"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterAdjustmentStockCategory
                    v-model:value="state.params.category"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%"
                    :options="state.statusLists"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.adjustment_code"
                    placeholder="Cari kode adjustment..."
                    allow-clear
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- form tables -->
        <AForm
            ref="formRefTable"
            :model="form"
            :rules="state.rules"
            @finish="btnActionRequest"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">
            <!-- button action menu -->
            <div class="row justify-content-end mt-4">
                <div class="col-lg-6 col-md-12"></div>
                <div class="col-lg-6 col-md-12 text-right">
                    <ASpace>
                        <ATooltip
                            v-if="hasPermissions(['adjustment-stock.approval'])"
                            title="Simpan">
                            <AButton
                                :loading="state.action.loading"
                                html-type="submit"
                                type="primary">
                                <span class="fa fa-send" aria-hidden="true"/>
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Download excel">
                            <DownloadExcel
                                :url="state.endpoint.current"
                                :params="queryParams()"
                                @errors="errorMessage"/>
                        </ATooltip>
                    </ASpace>
                </div>
            </div>
    
            <!-- list table -->
            <div class="table-responsive text-nowrap mt-4">
                <MdTable
                    :columns="state.columns"
                    :data-source="state.data"
                    size="small"
                    row-key="id"
                    :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                    :pagination="{
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                        showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                        total: state.meta.total,
                        pageSize: state.meta.per_page,
                        current: state.meta.page,
                    }"
                    @change="handleTableChange"
                    :loading="state.isFetching">
    
                    <template #no="{ index }">
                        <span>
                            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                        </span>
                    </template>
    
                    <template #buttons="{ index, record }">
                        <ARadioGroup
                            v-model:value="record.action_status"
                            @input="listenActionButton($event, index, record.adjustment_code)"
                            :key="index">
                            <ASpace>
                                <ATooltip title="Approve">
                                    <ARadioButton
                                        class="approve"
                                        shape="circle"
                                        :id="record.adjustment_code"
                                        :disabled="record.isRequest"
                                        value="approved">
                                        <i class="fa fa-check" aria-hidden="true"/>
                                    </ARadioButton>
                                </ATooltip>
                                <ATooltip title="Reject">
                                    <ARadioButton
                                        class="reject"
                                        shape="circle"
                                        :id="record.adjustment_code"
                                        :disabled="record.isRequest"
                                        value="rejected">
                                        <i class="fa fa-times" aria-hidden="true"/>
                                    </ARadioButton>
                                </ATooltip>
                            </ASpace>
                        </ARadioGroup>
                    </template>
        
                    <template
                        #reason="{ index, record }">
                        <span v-if="record.isRequest">{{ record.reason }}</span>

                        <AFormItem
                            v-else
                            :name="['children', index, 'reason']">
                            <ATextarea
                                v-model:value="record.reason"
                                :key="index"
                                placeholder="Reason"
                                allow-clear
                                style="width:200px;"
                                @input="listenActionNote($event, index, record.adjustment_code)"/>
                        </AFormItem>
                    </template>

                    <template #action="{ record }">
                        <ASpace>
                            <ATooltip
                                title="List Produk"
                                placement="topRight">
                                <AButton
                                    class="button"
                                    size="small"
                                    @click="btnProductList(record)">
                                    <i class="fa fa-list-alt" />
                                </AButton>
                            </ATooltip>
                            <ATooltip
                                v-if="hasPermissions(['adjustment-stock.live-stock'])"
                                title="Detail Stock Live Di ATBusiness"
                                placement="topRight">
                                <AButton
                                    class="button"
                                    size="small"
                                    @click="btnLiveStockList(record)">
                                    <i class="fa fa-list" />
                                </AButton>
                            </ATooltip>
                            <ATooltip
                                v-if="hasPermissions(['adjustment-stock.history-approval'])"
                                title="List History Action"
                                placement="topRight">
                                <AButton
                                    class="button"
                                    size="small"
                                    @click="btnHistoryActionList(record)">
                                    <i class="fa fa-users" />
                                </AButton>
                            </ATooltip>
                        </ASpace>
                    </template>
                </MdTable>
            </div>
        </AForm>
        <!-- end form tables -->

        <!-- product -->
        <ProductList
            v-if="visibleProductListModal"
            v-model:visible="visibleProductListModal"
            v-model:item="visibleProductListItemModal"/>

        <!-- history action -->
        <HistoryActionList
            v-if="visibleHistoryActionListModal"
            v-model:visible="visibleHistoryActionListModal"
            v-model:item="visibleHistoryActionListItemModal"/>

        <!-- live stock -->
        <LiveStockList
            v-if="visibleLiveStockListModal"
            v-model:visible="visibleLiveStockListModal"
            v-model:item="visibleLiveStockListItemModal"/>
        
        <!-- message errors -->
        <MessageErrors
            v-model:visible="state.errors.visible"
            :data="state.errors.data"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterProduct from '@/components/filter/FilterProduct'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterAdjustmentStockCategory from '@/components/filter/FilterAdjustmentStockCategory'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import MessageErrors from '@/components/Molecules/MessageErrors'
import Form from 'vform'
import ProductList from './ProductList'
import HistoryActionList from './HistoryActionList'
import LiveStockList from './LiveStockList'
import {
    hasRoles,
    hasPermissions,
    ROLE_ADMIN_BK,
    ROLE_TIM_IO,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        DownloadExcel,
        MessageErrors,
        FilterDistributor,
        FilterGudang,
        FilterProduct,
        FilterRegional,
        FilterArea,
        FilterKabupatenKota,
        FilterAdjustmentStockCategory,
        ProductList,
        HistoryActionList,
        LiveStockList,
    },
    setup() {
        const errorMessage = ref()
        const formRefTable = ref()
        const form = reactive(new Form({
            children: [],
        }))
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: ' ',
                    key: 'buttons',
                    slots: { customRender: 'buttons' },
                },
                {
                    title: 'Reason',
                    key: 'reason',
                    width: 200,
                    slots: { customRender: 'reason' },
                },
                {
                    title: 'Kode Adjustment',
                    dataIndex: 'adjustment_code',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse',
                },
                {
                    title: 'Status Gudang',
                    dataIndex: 'warehouse_status',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Kategori',
                    dataIndex: 'category',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'notes',
                    customRender: ({ text }) => h("div", { innerHTML: decodeHtml(text) }),
                },
                {
                    title: 'Tanggal Pengajuan',
                    dataIndex: 'date',
                },
                {
                    title: 'Action',
                    key: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/report/adjustment-stock-approvals',
                request: '/api/report/adjustment-stock-approvals',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                gudang: [],
                product: [],
                area: [],
                regional: [],
                kabupaten: [],
                category: [],
                status: 'waiting',
                adjustment_code: null,
                page: 1,
                "per-page": 10,
            }),
            action: {
                loading: false,
                data: [],
            },
            errors: {
                visible: false,
                data: [],
            },
            statusLists: [
                {
                    label: "Waiting",
                    value: 'waiting',
                },
                {
                    label: "Approved",
                    value: 'approved',
                },
                {
                    label: "Rejected",
                    value: 'rejected',
                },
            ],
            rules: {
                children: [],
            },
            rule_references: {
                reason: [
                    {
                        required: false,
                        message: "Reason tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isRequest = item.status != 'waiting'

                        // validate form before submit
                        state.rules.children.push(Object.assign({}, {
                            ...state.rule_references,
                            adjustment_code: item.adjustment_code,
                        }))
                        form.children.push(Object.assign({}, {reason: null}))
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActionRequest = () => {
            if (state.action.data.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            state.action.loading = true

            apiClient.put(state.endpoint.request, {
                    data: state.action.data,
                })
                .then(({ data }) => {
                    state.errors.visible = true
                    state.errors.data = [
                        ...data.errors.map(item => ({
                            message: Array.isArray(item.message) ? item.message[0] : item.message,
                            status: false,
                        })),
                        ...data.success.map(() => ({
                            status: true,
                        })),
                    ]

                    fetchDataList()
                })
                .catch((error) => {
                    errorMessage.value = error.response.data.message
                })
                .finally(() => {
                    state.action.loading = false
                    state.action.data = []
                })
        }

        const listenActionButton = (event, index, id) => {
            let status = event.target.value

            let find = state.action.data.find(item => item.adjustment_code == id)
            let first = state.data.find(item => item.adjustment_code == id)

            if (!find && first) {
                state.action.data.push({
                    status,
                    adjustment_code: first.adjustment_code,
                    distributor_code: first.distributor_code,
                    warehouse_code: first.warehouse_code,
                })
            } else {
                find.status = status
            }

            // reset reason default null
            if (status == 'approved') {
                form.children[index].reason = null
                first.reason = null
            }

            // Buat payload baru yang mencakup hanya data yang perlu diubah
            const updatedRules = state.rules.children.map(rule => {
                if (rule.adjustment_code === id) {
                    return {
                        ...rule,
                        reason: rule.reason.map(reasonItem => ({
                            ...reasonItem,
                            required: status == 'rejected',
                        })),
                    }
                }
                return rule // Biarkan data lain tidak berubah
            })

            // Replace semua data dengan payload baru
            state.rules.children = updatedRules
        }

        const listenActionNote = (event, index, id) => {
            let reason = event.target.value

            let find = state.action.data.find(item => item.adjustment_code == id)
            if (find) {
                find.reason =reason
            } else {
                state.action.data.push({
                    adjustment_code: id,
                    reason,
                })
            }

            form.children[index].reason = reason
        }

        const decodeHtml = (html) => {
            const textarea = document.createElement("textarea")
            textarea.innerHTML = html
            return textarea.value
        }

        // handle product list
        const visibleProductListModal = ref(false);
        const visibleProductListItemModal = ref(null);

        const btnProductList = (item = null) => {
            visibleProductListModal.value = true
            visibleProductListItemModal.value = item
        }

        // handle history action list
        const visibleHistoryActionListModal = ref(false);
        const visibleHistoryActionListItemModal = ref(null);

        const btnHistoryActionList = (item = null) => {
            visibleHistoryActionListModal.value = true
            visibleHistoryActionListItemModal.value = item
        }

        // handle live stock list
        const visibleLiveStockListModal = ref(false);
        const visibleLiveStockListItemModal = ref(null);

        const btnLiveStockList = (item = null) => {
            visibleLiveStockListModal.value = true
            visibleLiveStockListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // delete columns ketika semua permission tidak ada
            if (!hasPermissions(['adjustment-stock.approval'])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['buttons', 'reason'], item.key)) {
                        return item
                    }
                })
            }
        })

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            handleTableChange,
            btnActionRequest,
            listenActionButton,
            listenActionNote,
            formRefTable,
            form,
            btnProductList,
            visibleProductListModal,
            visibleProductListItemModal,
            btnHistoryActionList,
            visibleHistoryActionListModal,
            visibleHistoryActionListItemModal,
            btnLiveStockList,
            visibleLiveStockListModal,
            visibleLiveStockListItemModal,
            // only variable role
            hasRoles,
            hasPermissions,
            ROLE_ADMIN_BK,
            ROLE_TIM_IO,
        }
    },
});
</script>

<style scoped lang="scss">
.approve,
.reject {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;

    &.ant-radio-button-wrapper-checked {
        color: white !important;
    }
}
.approve {
    padding: 0px 8px !important;
    color: #009688;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #009688 !important;
        border-color: #009688 !important;
    }
}
.reject {
    padding: 0px 10px !important;
    color: #f44336;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #f44336 !important;
        border-color: #f44336 !important;
    }
}
</style>