<template>
    <AModal
        :visible="visible"
        title="List History Action"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { pickBy } from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
    DatetimeFormatHuman,
} from '@/helpers/utils'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Tanggal Action',
                    dataIndex: 'approved_at',
                    customRender: ({ text }) => DatetimeFormatHuman(text),
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Action By',
                    dataIndex: 'approved_by',
                },
            ],
            endpoint: {
                current: `/api/report/adjustment-stock/history-approval`,
            },
            isFetching: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                q: '',
                adjustment_code: props.item.adjustment_code,
                distributor_code: props.item.distributor_code,
                warehouse_code: props.item.warehouse_code,
                page: 1,
                "per-page": 10,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            handleModalCancel,
            handleTableChange,
            fetchDataList,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
